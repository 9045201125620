import * as newColors from 'src/styles/newColors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  h1, h2, h3, h4, h5 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }
`

export const PopupContent = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

  .popup_content__texts{
    &__title{
      font-family: "Citrina", Helvetica, sans-serif;
      font-weight: 500;
      font-size: 36px;
      line-height: 43px;
      color: ${newColors.orange.dark};

      margin-bottom: 16px;
    }
    &__description{
      font-family: "Inter", Helvetica, sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: ${newColors.orange.extra};

      &--highlighted{
        font-family: "Citrina", Helvetica, sans-serif;
        font-weight: 500;
        text-decoration: underline;
        font-size: 36px;
        line-height: 46px;
      }
    }
  }
`
