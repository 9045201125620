import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Checkbox } from './style'

type InterDayProps = {
  accept: boolean;
  setAccept: Function;
  name: string;
  styles?: string;
}
function ContaDigitalPJ ({ accept, setAccept, name, styles }: InterDayProps) {
  const [ sendDatalayerEvent ] = useDataLayer()
  const url = 'https://inter.co/politica-de-privacidade/privacidade/'

  function handleCheck (event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    setAccept(inputValue)
  }

  return (
    <Checkbox>
      <input
        name={name}
        value={accept.toString()}
        id={name}
        type='checkbox'
        className='form-input-check'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheck(event)}
        checked={accept}
      />
      <label className={`fs-14 lh-17 form-label-check ${styles}`} htmlFor={name}>
        Li e concordo com os Termos e Condições de Abertura de Conta, com os Termos e Condições de Uso Super App e com a
        <a
          className='fw-700 text-orange--extra'
          title='Política de Privacidade'
          href={url}
          target='_blank' rel='noreferrer'
          onClick={() => {
            sendDatalayerEvent({
              section: 'dobra_01',
              element_action: 'click button',
              element_name: 'Política de Privacidade',
              section_name: 'Conta PJ Digital: destrave novas possibilidades pro seu negócio',
              redirect_url: url,
            })
          }}
        > Política de Privacidade
        </a> do Inter.
      </label>
    </Checkbox>
  )
}

ContaDigitalPJ.defaultProps = {
  name: 'aceite',
  styles: 'fs-12 lh-15',
}

export default ContaDigitalPJ
