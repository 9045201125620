import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'
import grafismoLg from '../../assets/images/grafismo-formulario-hero-lg.png'
import grafismo from '../../assets/images/grafismo-formulario-hero.png'

export const Section = styled.section`
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 39%, rgba(234,113,0,1) 23%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;


  .bread-crumb {
    span, a {
      color: ${white} !important;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    background-color: ${orange[500]};
    min-height: 728px;
    background-image: url(${grafismoLg});
    background-repeat: no-repeat;
    background-size: 468px;
    background-position-x: calc(100% - -120px);
    background-position-y: 41px
  }

  @media (min-width: ${breakpoints.lg}) {
    background-color: ${orange[500]};
    min-height: 728px;
    background-image: url(${grafismoLg});
    background-repeat: no-repeat;
    background-position: right;
  }


  @media (min-width: ${breakpoints.xl}) {
    background-color: ${orange[500]};
    min-height: 702px;
    background-image: url(${grafismo});
    background-repeat: no-repeat;
    background-size: 598px;
    background-position-x: calc(100% - 43px) ;
    background-position-y: 45px;
  }

  h1 {
    color: ${orange.extra};

    @media (min-width: ${breakpoints.md}) {
      color:  ${white};
    }
  }

 p {
   color: ${grayscale[500]};

    @media (min-width: ${breakpoints.md}) {
      color:  ${white};
    }
  }

`
export const ScrollLink = styled.div`
  position: absolute;
  bottom: 1px;
  left: 50%;
  text-align: center;
  line-height: 1.67;
  transform: translateX(-50%);
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    bottom: -69px;
  }

  @media (min-width: ${breakpoints.lg}){
    bottom: -88px;
  }

  @media (min-width: ${breakpoints.xl}){
    bottom: -74px;
  }


`

export const Card = styled.div`
  background-color: ${white};
  border-radius: 16px;
  width: 100%;
  padding: 24px;
  margin-top: 45px ;


  @media (min-width: ${breakpoints.lg}) {
    width: 471px;
    height: 512px ;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 539px;
    height: 521px ;
  }

  > p {
    font-family: 'Citrina', Helvetica, sans-serif;
    color: ${grayscale[500]};
  }

  input {
    background-color: #F5F6FA;
    border: none;
    border-radius: 8px;
    padding: 16px;
    height: 48px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    width: 100%;


    &:placeholder {
      color: #B6B7BB;
    }
  }



`
export const Button = styled.a`
  background: ${orange.extra};
  border: none;
  border-radius: 8px;
  height: 48px;
  width: 100%;
  color: ${white};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus, &:hover {
    outline: none;
    color: ${white};
  }

`

export const ButtonReverse = styled.a`
  background: none;
  border: 1px solid ${orange.extra};
  border-radius: 8px;
  height: 48px;
  width: 100%;
  color: ${orange.extra};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;

  &:focus, &:hover {
    outline: none;
    color: ${orange.extra};
  }


  @media (min-width: ${breakpoints.md}) {
    margin-top: 16px;
  }

`
export const ButtonDisable = styled.button`
  background: #dedfe4;
  border: none;
  border-radius: 8px;
  height: 48px;
  width: 100%;
  color: #b6b7bb;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
`
