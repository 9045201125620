import React, { useEffect, useLayoutEffect, useState } from 'react'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD } from 'src/utils/breakpoints'

import { SolutionsTable, TableCell } from './style'

const ContaEmpresarial = () => {
  const [ tableHeader, setTableHeader ] = useState(false)
  const [ isMobile, seIsMobile ] = useState(false)
  const width = useWidth()

    const contentFixed = typeof window !== 'undefined' && document.querySelectorAll('.headerTable')
    let windowHeight = typeof window !== 'undefined' && window.innerHeight

    function fixedHeader () {
      contentFixed && contentFixed.forEach((div) => {
        const bounding = div.getBoundingClientRect()
        if (bounding.bottom > windowHeight) {
          setTableHeader(true)
        } else if (bounding.top < 0) {
          setTableHeader(true)
        } else {
          setTableHeader(false)
        }
      })
    }

    typeof window !== 'undefined' && document.addEventListener('scroll', function () {
      document.removeEventListener('scroll', fixedHeader)
    })

    typeof window !== 'undefined' && window.addEventListener('resize', function () {
      windowHeight = window.innerHeight
      window.removeEventListener('resize', fixedHeader)
    })

    useLayoutEffect(() => {
      seIsMobile(width < WIDTH_MD)
    }, [ width ])

    useEffect(() => {
      fixedHeader()
    })

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-xl-10 offset-xl-1'>
            <h2 className='fs-24 fs-lg-42 lh-30 fs-md-28 lh-md-33 lh-lg-54 fs-xl-42 lh-xl-50 text-orange--extra text-center fw-400'>
              <span className='d-inline d-lg-block fw-700'>Conta Empresarial:</span> soluções personalizadas
            </h2>
            <p className='fs-14 fs-lg-18 lh-16 lh-lg-23 text-center text-grayscale--500'>No Inter Empresas nosso relacionamento cresce junto com seu negócio. Garanta benefícios extras com nossos perfis de relacionamento exclusivos.</p>
            <SolutionsTable className='mt-5 mt-md-4 px-md-2'>
              <TableCell className={`col-md-8 offset-md-4 d-flex justify-content-between headerTable py-4 ${tableHeader && isMobile ? 'lineColorHeader' : ''}`}>
                {width < WIDTH_MD ? '' : <div className='d-flex align-items-center' />}
                <div className='col-4 d-flex align-items-center justify-content-center borderRight'>
                  <p className='fs-16 lh-17 fs-md-18 lh-md-22 mb-0 fw-700 text-orange--extra text-center'>Digital</p>
                </div>
                <div className='col-4 d-flex align-items-center justify-content-center borderRight'>
                  <p className='fs-12 mb-0 fw-400 lh-22 text-orange--extra text-center'>
                    <span className='d-block fs-16 lh-17 fs-md-18 fw-700'>Pro</span>
                    <a target='_blank' rel='noreferrer' href='/empresas/relacionamento/pj-pro/'>Saiba mais</a>
                  </p>
                </div>
                <div className='col-4 d-flex align-items-center justify-content-center'>
                  <p className='fs-12 mb-0 fw-400lh-22 text-orange--extra text-center'>
                    <span className='d-block fs-16 lh-17 fs-md-18 fw-700'>Enterprise</span>
                    <u><a target='_blank' rel='noreferrer' href='/empresas/relacionamento/pj-enterprise/'>Saiba mais</a></u>
                  </p>
                </div>
              </TableCell>

              <TableCell className='row d-flex justify-content-between lineColor px-1'>
                <div className='col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start py-3 tableTop'>
                  <p className='fs-14 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500'>Pix gratuito</p>
                </div>
                <div className='col-12 col-md-8 d-flex justify-content-between py-3'>
                  <div className='col-4 d-flex align-items-center justify-content-center px-3'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                </div>
              </TableCell>

              <TableCell className='row d-flex justify-content-between lineColor px-1'>
                <div className='col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start py-3 tableTop'>
                  <p className='fs-14 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500'>Antecipação de Recebíveis de Cartão</p>
                </div>
                <div className='col-12 col-md-8 d-flex justify-content-between py-3'>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                </div>
              </TableCell>

              <TableCell className='row d-flex justify-content-between lineColor px-1'>
                <div className='col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start py-3 tableTop'>
                  <p className='fs-14 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500 text-center text-md-left'>Cartão sem Anuidade</p>
                </div>
                <div className='col-12 col-md-8 d-flex justify-content-between py-3'>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                </div>
              </TableCell>

              <TableCell className='row d-flex justify-content-between lineColor px-1'>
                <div className='col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start py-3 tableTop'>
                  <p className='fs-14 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500 text-center text-md-left'>Folha de Pagamento e remessas via Cnab e Excel</p>
                </div>
                <div className='col-12 col-md-8 d-flex justify-content-between py-3'>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                </div>
              </TableCell>

              <TableCell className='row d-flex justify-content-between lineColor px-1'>
                <div className='col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start py-3 tableTop'>
                  <p className='fs-14 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500 text-center text-md-left'>Gestão de Cobranças completa</p>
                </div>
                <div className='col-12 col-md-8 d-flex justify-content-between py-3'>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                </div>
              </TableCell>

              <TableCell className='row d-flex justify-content-between lineColor px-1'>
                <div className='col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start py-3 tableTop'>
                  <p className='fs-14 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500 text-center text-md-left'>Advisor</p>
                </div>
                <div className='col-12 col-md-8 d-flex justify-content-between py-3'>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#F56A50' icon='exit' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                </div>
              </TableCell>

              <TableCell className='row d-flex justify-content-between lineColor px-1'>
                <div className='col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start py-3 tableTop'>
                  <p className='fs-14 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500 text-center text-md-left'>Investimentos Empresariais Exclusivos</p>
                </div>
                <div className='col-12 col-md-8 d-flex justify-content-between py-3'>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#F56A50' icon='exit' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                </div>
              </TableCell>

              <TableCell className='row d-flex justify-content-between lineColor px-1'>
                <div className='col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start py-3 tableTop'>
                  <p className='fs-14 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500 text-center text-md-left'>Atendimento exclusivo com Advisor via WhatsApp</p>
                </div>
                <div className='col-12 col-md-8 d-flex justify-content-between py-3'>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#F56A50' icon='exit' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#F56A50' icon='exit' />
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <OrangeIcon size='MD' color='#00AA4F' icon='check' />
                  </div>
                </div>
              </TableCell>

              <TableCell className='row d-flex justify-content-between lineColor px-1 tableBottom'>
                <div className='col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start py-3 tableTop'>
                  <p className='fs-14 lh-15 fs-md-14 lh-md-18 fs-lg-18 lh-lg-22 mb-0 text-grayscale--500 text-center text-md-left'>Liquidação de Boletos</p>
                </div>
                <div className='col-12 col-md-8 d-flex justify-content-between py-3'>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <p className='fs-14 lh-18 text-grayscale--500 fw-700 mb-0'>30</p>
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <p className='fs-14 lh-18 text-grayscale--500 fw-700 mb-0'>60</p>
                  </div>
                  <div className='col-4 d-flex align-items-center justify-content-center'>
                    <p className='fs-14 lh-18 text-grayscale--500 fw-700 mb-0'>100</p>
                  </div>
                </div>
              </TableCell>
            </SolutionsTable>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContaEmpresarial
